// extracted by mini-css-extract-plugin
export var ammoCaseStudyVideo = "bG_lx";
export var caseContactFormSection = "bG_kC";
export var caseSolutionRing = "bG_ly";
export var caseStudyBackground__bgColor = "bG_ky";
export var caseStudyBackground__lineColor = "bG_kz";
export var caseStudyCta__bgColor = "bG_kP";
export var caseStudyHead = "bG_kt";
export var caseStudyHead__imageWrapper = "bG_kv";
export var caseStudyProjectsSection = "bG_kD";
export var caseStudyQuote__dark = "bG_lt";
export var caseStudyQuote__light = "bG_lv";
export var caseStudySolution__ringOne = "bG_lw";
export var caseStudySolution__ringTwo = "bG_kJ";
export var caseStudyTechSection = "bG_kB";
export var caseStudyVideo__ring = "bG_kN";
export var caseStudy__bgDark = "bG_ks";
export var caseStudy__bgLight = "bG_kr";